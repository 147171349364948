
import Component from "vue-class-component";
import Vue from "vue";

export type LogItem = {
  type: string;
  date: number;
  symbol: string;
  timeframe: string | null;
  message: string;
  id: number;
};

@Component({})
export default class LiveDataLogStream extends Vue {
  private logItems: Array<LogItem> = [];
  private lastId = 0;
  type: string | null = null;
  date: string | null = null;
  symbol: string | null = null;
  timeframe: string | null = null;
  message: string | null = null;

  get logItemsFiltered() {
    let logItems = this.logItems;
    if (this.type) {
      logItems = logItems.filter(
        (logItem) => logItem.type.toLowerCase().indexOf(this.type?.toLowerCase() || "") !== -1
      );
    }
    if (this.date) {
      logItems = logItems.filter((logItem) => logItem.date.toString().indexOf(this.date || "") !== -1);
    }
    if (this.symbol) {
      logItems = logItems.filter(
        (logItem) => logItem.symbol.toLowerCase().indexOf(this.symbol?.toLowerCase() || "") !== -1
      );
    }
    if (this.timeframe) {
      logItems = logItems.filter((logItem) => logItem.timeframe && logItem.timeframe === this.timeframe);
    }
    if (this.message) {
      logItems = logItems.filter(
        (logItem) => logItem.message.toLowerCase().indexOf(this.message?.toLowerCase() || "") !== -1
      );
    }
    if (logItems.length > 500) {
      logItems = logItems.slice(0, 500);
    }
    return logItems;
  }

  addLogItem(logItem: LogItem) {
    logItem.id = ++this.lastId;
    this.logItems.push(logItem);
  }
}
