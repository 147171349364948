
import Component from "vue-class-component";
import Vue from "vue";
import ProcessedTrades from "@/components/EngineStatus/Components/ProcessedTrades.vue";
import MaxJitter from "@/components/EngineStatus/Components/MaxJitter.vue";
import { EChartOption } from "echarts";
import echarts from "echarts/lib/echarts";
import DataZoom = echarts.EChartOption.DataZoom;
import ExchangeRequests from "@/components/EngineStatus/Components/ExchangeRequests.vue";
import { Prop } from "vue-property-decorator";
import ReconnectingWebSocket from "reconnecting-websocket";

type CacheInfo = { symbol: string; timeframe: string; marketOhlcvId: number };
type CacheStatusUpdate = { isFetching: Array<number>; isStopped: Array<number> };

@Component({
  components: { ExchangeRequests, ProcessedTrades, MaxJitter },
})
export default class LiveDataCharts extends Vue {
  lastMaxTimeDiffInMsInStream?: number;
  processedTrades!: ProcessedTrades;
  maxJitterStream!: MaxJitter;
  maxJitterFetch!: MaxJitter;
  maxJitterProcessStream!: MaxJitter;
  maxJitterProcessFetch!: MaxJitter;
  maxJitterProcessFuture!: MaxJitter;
  startedExchangeRequests!: ExchangeRequests;
  outStandingExchangeRequests!: ExchangeRequests;
  exchangeTrades!: ExchangeRequests;
  futureTrades!: ExchangeRequests;

  cacheInfos: Array<CacheInfo> = [];
  cacheStatusUpdate: CacheStatusUpdate = { isFetching: [], isStopped: [] };

  @Prop()
  connection?: ReconnectingWebSocket | null;

  mounted() {
    this.processedTrades = this.$refs.processedTrades as ProcessedTrades;
    this.maxJitterStream = this.$refs.maxJitterStream as MaxJitter;
    this.maxJitterFetch = this.$refs.maxJitterFetch as MaxJitter;
    this.maxJitterProcessStream = this.$refs.maxJitterProcessStream as MaxJitter;
    this.maxJitterProcessFetch = this.$refs.maxJitterProcessFetch as MaxJitter;
    this.maxJitterProcessFuture = this.$refs.maxJitterProcessFuture as MaxJitter;
    this.startedExchangeRequests = this.$refs.startedExchangeRequests as ExchangeRequests;
    this.outStandingExchangeRequests = this.$refs.outStandingExchangeRequests as ExchangeRequests;
    this.exchangeTrades = this.$refs.exchangeTrades as ExchangeRequests;
    this.futureTrades = this.$refs.futureTrades as ExchangeRequests;

    this.connection?.send("statsItemAll");
  }

  private onZoom(e: DataZoom) {
    this.$emit("data-zoom", e);
  }

  setZoom(e: DataZoom) {
    this.processedTrades.setZoom(e);
    this.maxJitterStream.setZoom(e);
    this.maxJitterFetch.setZoom(e);
    this.maxJitterProcessStream.setZoom(e);
    this.maxJitterProcessFetch.setZoom(e);
    this.maxJitterProcessFuture.setZoom(e);
    this.startedExchangeRequests.setZoom(e);
    this.outStandingExchangeRequests.setZoom(e);
    this.exchangeTrades.setZoom(e);
    this.futureTrades.setZoom(e);
  }

  onAfterAddItem() {
    this.processedTrades.onAfterAddItem();
    this.maxJitterStream.onAfterAddItem();
    this.maxJitterFetch.onAfterAddItem();
    this.maxJitterProcessStream.onAfterAddItem();
    this.maxJitterProcessFetch.onAfterAddItem();
    this.maxJitterProcessFuture.onAfterAddItem();
    this.startedExchangeRequests.onAfterAddItem();
    this.outStandingExchangeRequests.onAfterAddItem();
    this.exchangeTrades.onAfterAddItem();
    this.futureTrades.onAfterAddItem();
  }

  addItem(data: any) {
    if (data.timestamp === undefined) {
      return;
    }

    const time = LiveDataCharts.getTime(data.timestamp);
    const maxTimeDiffInMsInStream = data.maxTimeDiffInMsInStream ?? this.lastMaxTimeDiffInMsInStream;

    this.processedTrades.addItem(time, data.processed);
    this.maxJitterStream.addItem(time, maxTimeDiffInMsInStream);
    this.maxJitterFetch.addItem(time, data.maxTimeDiffInMsForFetch);
    this.maxJitterProcessStream.addItem(time, data.maxTimeDiffInMsForProcessStream);
    this.maxJitterProcessFetch.addItem(time, data.maxTimeDiffInMsForProcessFetch);
    this.maxJitterProcessFuture.addItem(time, data.maxTimeDiffInMsForProcessFuture);
    this.startedExchangeRequests.addItem(time, data.startedExchangeRequests);
    this.outStandingExchangeRequests.addItem(time, data.outStandingExchangeRequests);
    this.exchangeTrades.addItem(time, data.sumNumberOfTrades);
    this.futureTrades.addItem(time, data.sumNumberOfFutureTrades);

    this.lastMaxTimeDiffInMsInStream = maxTimeDiffInMsInStream;
  }

  resetData() {
    this.processedTrades.resetData();
    this.maxJitterStream.resetData();
    this.maxJitterFetch.resetData();
    this.maxJitterProcessStream.resetData();
    this.maxJitterProcessFetch.resetData();
    this.maxJitterProcessFuture.resetData();
    this.startedExchangeRequests.resetData();
    this.outStandingExchangeRequests.resetData();
    this.exchangeTrades.resetData();
    this.futureTrades.resetData();
  }

  private static getTime(timestamp: number) {
    const date = new Date(timestamp);
    return (
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0") +
      ":" +
      date.getSeconds().toString().padStart(2, "0")
    );
  }
}
