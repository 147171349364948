
import Component from "vue-class-component";
import Vue from "vue";
import { EChartOption, ECharts, EChartTitleOption } from "echarts";
import echarts from "echarts/lib/echarts";
import DataZoom = echarts.EChartOption.DataZoom;
import { Prop } from "vue-property-decorator";

@Component
export default class ExchangeRequests extends Vue {
  @Prop()
  title?: string;

  @Prop()
  unit?: string;

  @Prop({ default: 1 })
  splitNumber?: number;

  eChartInstance!: ECharts;

  created() {
    if (this.title !== undefined) {
      (this.option.title as EChartTitleOption).text = this.title;
    }

    if (this.unit !== undefined) {
      // @ts-ignore
      (this.option.yAxis as EChartOption.YAxis).axisLabel.formatter = "{value} " + this.unit;
    }

    if (this.splitNumber !== undefined) {
      // @ts-ignore
      (this.option.yAxis as EChartOption.YAxis).splitNumber = this.splitNumber;
    }
  }

  private updateInstance(eChartInstance: ECharts) {
    this.eChartInstance = eChartInstance;
    this.eChartInstance.on("dataZoom", (e: object) => {
      this.$emit("data-zoom", e);
    });
  }

  addItem(time: string, exchangeRequests: number) {
    // @ts-ignore
    const source = this.option.dataset.source;
    source.push([time, exchangeRequests]);
    if (source.length >= 3600) {
      source.shift();
    }
    // @ts-ignore
    this.option.series[0].markPoint.data[0] = {
      value: exchangeRequests,
      xAxis: source.length - 1,
      yAxis: exchangeRequests,
    };
  }

  resetData() {
    // @ts-ignore
    this.option.dataset.source = [];
    // @ts-ignore
    this.option.series[0].markPoint.data[0] = {};
  }

  onAfterAddItem() {
    // @ts-ignore
    this.option.dataZoom[0] = this.eChartInstance.getOption().dataZoom[0];
  }

  setZoom(e: DataZoom) {
    // @ts-ignore
    if (this.option.dataZoom[0].start === e.start && this.option.dataZoom[0].end === e.end) {
      return;
    }
    // @ts-ignore
    this.option.dataZoom[0].start = e.start;
    // @ts-ignore
    this.option.dataZoom[0].end = e.end;
    this.eChartInstance.dispatchAction({ type: "dataZoom", start: e.start, end: e.end });
  }

  option: EChartOption = {
    animation: false,
    title: {
      text: "Exchange requests",
      top: 10,
      textStyle: {
        fontWeight: "normal",
        fontFamily: "Roboto, sans-serif",
      },
    },
    color: ["#2196f3"],
    dataset: {
      source: [],
    },
    dataZoom: [
      {
        type: "slider",
        start: 85,
        end: 100,
      },
    ],
    grid: {
      left: 60,
      right: 60,
    },
    tooltip: {
      transitionDuration: 0,
      trigger: "axis",
      position: function (point: any) {
        // fixed at top
        return [point[0], "10%"];
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#aaa",
        },
      },
      axisLabel: {
        color: "#333",
      },
      splitLine: {
        show: false,
      },
      boundaryGap: false,
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "20%"],
      min: 0,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        formatter: "{value}",
      },
      splitLine: {
        lineStyle: {
          color: "#eee",
        },
      },
    },
    series: [
      {
        type: "line",
        showSymbol: false,
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          opacity: 0.2,
        },
        encode: {
          x: 0,
          y: 1,
        },
        markPoint: {
          data: [{}, { type: "max", name: "Max" }],
        },
      },
    ],
  };
}
