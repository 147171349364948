
import Component from "vue-class-component";
import Vue from "vue";
import LiveData from "@/components/EngineStatus/LiveData.vue";
import { EChartOption } from "echarts";
import echarts from "echarts/lib/echarts";
import DataZoom = echarts.EChartOption.DataZoom;
@Component({
  components: { LiveData },
})
export default class EngineStatus extends Vue {
  tab = 0;

  onZoom(e: DataZoom) {
    // @ts-ignore
    (this.$refs.liveData as LiveData).setZoom(e);
  }
}
