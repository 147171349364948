
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { MarketInfos } from "@/components/EngineStatus/LiveDataStatus.vue";

@Component({})
export default class StatusBoxTradeOhlcv extends Vue {
  @Prop()
  title?: string;

  @Prop()
  updates?: Array<number>;

  @Prop()
  marketInfos?: MarketInfos;
}
