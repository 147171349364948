
import Component from "vue-class-component";
import Vue from "vue";
import ReconnectingWebSocket from "reconnecting-websocket";
import { EChartOption } from "echarts";
import echarts from "echarts/lib/echarts";
import DataZoom = echarts.EChartOption.DataZoom;
import LiveDataStatus from "@/components/EngineStatus/LiveDataStatus.vue";
import LiveDataCharts from "@/components/EngineStatus/LiveDataCharts.vue";
import LiveDataLogStream from "@/components/EngineStatus/LiveDataLogStream.vue";

@Component({
  components: { LiveDataLogStream, LiveDataStatus, LiveDataCharts },
})
export default class LiveData extends Vue {
  tab = 0;

  connection: ReconnectingWebSocket | null = null;

  mounted() {
    this.connect();
  }

  private onZoom(e: DataZoom) {
    this.$emit("data-zoom", e);
  }

  connect() {
    //const liveDataCharts = this.$refs.liveDataCharts as LiveDataCharts;

    this.connection = new ReconnectingWebSocket("wss://" + location.host + "/live-data/socket");
    this.connection.onopen = () => {
      //liveDataCharts.resetData();
    };
    this.connection.onmessage = (e) => {
      const liveDataStatus = this.$refs.liveDataStatus as LiveDataStatus;
      const liveDataCharts = this.$refs.liveDataCharts as LiveDataCharts;
      const liveDataLogStream = this.$refs.liveDataLogStream as LiveDataLogStream;

      const message = JSON.parse(e.data);

      if (message.statsItemAll && liveDataCharts) {
        for (const item of message.statsItemAll) {
          liveDataCharts.addItem(item);
        }
      }
      if (message.marketInfos) {
        liveDataStatus.marketInfos = message.marketInfos;
      }

      if (message.statsItem && liveDataCharts) {
        liveDataCharts.addItem(message.statsItem);
      }
      if (message.cacheStatusUpdate) {
        liveDataStatus.cacheStatusUpdate = message.cacheStatusUpdate;
      }
      if (message.log && liveDataLogStream) {
        liveDataLogStream.addLogItem(message.log);
      }

      if (liveDataCharts) {
        liveDataCharts.onAfterAddItem();
      }
    };
  }

  destroyed() {
    this.connection?.close();
  }
}
