
import Vue from "vue";
import Component from "vue-class-component";
import { MarketOhlcvStatDTO } from "next-trader-api";
import { marketOhlcvApi } from "@/plugins/api";
import OhlcvStat from "@/components/OhlcvStat.vue";
import { mdiRefresh } from "@mdi/js";
import { Subscription } from "rxjs";

@Component({
  components: { OhlcvStat },
})
export default class OhlcvStatParent extends Vue {
  marketOhlcvStatDTOs: Array<MarketOhlcvStatDTO> = [];

  icons = {
    mdiRefresh: mdiRefresh,
  };

  loading = true;

  subscription?: Subscription;

  updateData() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = marketOhlcvApi.getAppMarketohlcvStat({}).subscribe((next: Array<MarketOhlcvStatDTO>) => {
      this.marketOhlcvStatDTOs = next.filter((statDTO) => {
        return (statDTO.progressValueSum || 0) < 100;
      });
      this.loading = false;
    });
  }

  created() {
    this.loading = true;

    this.updateData();
  }
}
