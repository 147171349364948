
import Component from "vue-class-component";
import Vue from "vue";
import StatusBoxOhlcv from "@/components/EngineStatus/Components/StatusBoxOhlcv.vue";
import StatusBoxTradeOhlcv from "@/components/EngineStatus/Components/StatusBoxTradeOhlcv.vue";

type MarketOhlcvInfo = { timeframe: string };
type MarketOhlcvInfos = {
  [marketOhlcvId: number]: MarketOhlcvInfo;
};
type MarketInfo = {
  symbol: string;
  cacheInfos: MarketOhlcvInfos;
};
export type MarketInfos = {
  [marketId: number]: MarketInfo;
};

export type MarketUpdates = {
  [marketId: number]: Array<number>;
};
type CacheStatusUpdate = {
  ohlcv: { isFetching: MarketUpdates; isStopped: MarketUpdates };
  ohlcv1m: { isFetching: MarketUpdates; isStopped: MarketUpdates };
  tradeOhlcv: { isFetching: Array<number>; isStopped: Array<number> };
};

@Component({
  components: { StatusBoxOhlcv, StatusBoxTradeOhlcv },
})
export default class LiveDataCharts extends Vue {
  marketInfos: MarketInfos = {};
  cacheStatusUpdate: CacheStatusUpdate = LiveDataCharts.createEmptyUpdate();

  private static createEmptyUpdate() {
    return {
      ohlcv: { isFetching: {}, isStopped: {} },
      ohlcv1m: { isFetching: {}, isStopped: {} },
      tradeOhlcv: { isFetching: [], isStopped: [] },
    };
  }
}
